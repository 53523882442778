//Variables to replace
const poolId = 'us-west-2_c6uOMjW7m'
const clientId = '606vlks8997n2acrcubeg7ikbc'
const api = 'https://o93ms6hx84.execute-api.us-west-2.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config